import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet-async";
import gif from "./innoit.gif";

function GetInTouch() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const textFields = [
    { id: "companyName", label: "Company Name" },
    { id: "yourName", label: "Your Name" },
    { id: "emailAddress", label: "Email address" },
    { id: "phoneNumber", label: "Phone Number" },
  ];

  const [formData, setFormData] = useState({
    companyName: "",
    yourName: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const [description, setDescription] = useState("");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateForm = () => {
    const { companyName, yourName, emailAddress, phoneNumber } = formData;
    if (!companyName || !yourName || !emailAddress || !phoneNumber) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill in all the required fields.");
      return;
    }
    setLoading(true);
    const templateParams = {
      company_name: formData.companyName,
      your_name: formData.yourName,
      email_address: formData.emailAddress,
      phone_number: formData.phoneNumber,
      description: description,
    };

    emailjs
      .send(
        "service_6dixfmb",
        "template_wex3e0g",
        templateParams,
        "8QcIg65YqsTK7GqnK"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({
            companyName: "",
            yourName: "",
            emailAddress: "",
            phoneNumber: "",
          });
          setLoading(false);
          alert("Email sent successfully!");
        },
        (err) => {
          console.log("FAILED...", err);
          alert("Failed to connect Us!!. Please try again later.");
        }
      );
  };

  return (
    <Grid container style={{ marginTop: "10vh" }}>
      <Helmet>
        <title>Contact Us - Get in Touch with ByteCrafts</title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact ByteCrafts today! Our dedicated team is here to help you with any inquiries regarding our innovative digital solutions."
        />
      </Helmet>

      <Dialog
        open={loading}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent>
          <img
            src={gif}
            alt="Loading..."
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        style={{
          fontSize: "40px",
          textAlign: "center",
          backgroundColor: "#fef4f3",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "93.5%",
            padding: isMobile ? "0px" : "20px",
            margin: isMobile ? "0" : "0 auto",
            paddingBottom: "40px",
            paddingTop: isMobile ? "20px" : "0px",
          }}
        >
          <b>Get in Touch!!!</b>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: isMobile ? "15ch" : "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            {textFields.map((field) => (
              <TextField
                key={field.id}
                id={field.id}
                label={field.label}
                variant="outlined"
                value={formData[field.id]}
                onChange={handleInputChange}
                InputProps={{
                  sx: {
                    backgroundColor: " #D9D9D933",
                  },
                }}
                sx={{
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: " #D9D9D933",
                    },
                  },
                }}
                fullWidth
                margin="normal"
              />
            ))}
          </Box>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: isMobile ? "15ch" : "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="description"
              label="Tell us more about your project"
              variant="outlined"
              onChange={(e) => setDescription(e.target.value)}
              InputProps={{
                sx: {
                  height: "200px",
                  backgroundColor: " #D9D9D933",
                },
              }}
              sx={{
                ".MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: " #D9D9D933",
                  },
                },
              }}
            />
            <Button
              onClick={(e) => handleSubmit(e)}
              variant="contained"
              sx={{
                backgroundColor: "#f9e3e3",
                color: "#000",
                width: "150px",
                height: "45px",
                borderRadius: "30px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#f4caca",
                },
              }}
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: isMobile ? "100%" : "50%",
            height: "100%",
            backgroundColor: "#fef4f3",
            zIndex: "1",
            marginRight: 0,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "80px",
          }}
        >
          <Box
            component="img"
            src="/contcatus/Getintouch.jpg"
            alt="Get in Touch"
            sx={{
              width: {
                xs: "300px",
                sm: "400px",
                md: "700px",
                lg: "700px",
                xl: "700px",
              },
              height: {
                xs: "280px",
                sm: "380px",
                md: "400px",
                lg: "450px",
                xl: "480px",
              },
              borderTopLeftRadius: "40px",
              borderBottomLeftRadius: "40px",
              borderTopRightRadius: {
                xs: "40px",
                md: "0px",
              },
              borderBottomRightRadius: {
                xs: "40px",
                md: "0px",
              },
              zIndex: 2,
              position: "relative",
            }}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4vh",
          marginBottom: "4vh",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.8525095760915!2d75.91676197480275!3d14.435662081094137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba25801488f567%3A0x1dee8cf47f9a23f1!2sByteCrafts!5e0!3m2!1sen!2sus!4v1728835241621!5m2!1sen!2sus"
          width="800"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Grid>
    </Grid>
  );
}

export default GetInTouch;
